<template>
<div>
  <b-table  hover :items="tutorGroup" :fields="tgFields" responsive="sm">
    <template #cell(progress)="data">
      <b-progress :value="data.value" :max="data.item.objectiveCount" show-value ></b-progress>
    </template>
    <template #cell(studentId)="data">
          <b-button-group>
            <b-button size="sm" @click="showStudent(data.value)" variant="outline-primary" title="Show Student"><b-icon-pencil-fill></b-icon-pencil-fill></b-button>
            <b-button size="sm" v-if="person.authLevel == authLevel.Admin && data.item.awardStatus === 0 && data.item.progress > 0" @click="giveAward(data.item)" variant="outline-primary" title="Give Award. In exceptional circumstances the pupil may be awarded even when all objectives have not been met."><b-icon-award-fill></b-icon-award-fill></b-button>
            <b-button size="sm" v-if="person.authLevel == authLevel.Admin && data.item.awardStatus === 1 && data.item.progress < data.item.objectiveCount" @click="undoAward(data.item)" variant="outline-danger" title="Undo Award."><b-icon-arrow-clockwise></b-icon-arrow-clockwise></b-button>
          </b-button-group>
          <span v-if="data.item.awardStatus === 1" class="award-pending">&nbsp;&nbsp;<b-icon-award-fill></b-icon-award-fill></span>
          <span v-if="data.item.awardStatus === 2" class="awarded">&nbsp;&nbsp;<b-icon-award-fill></b-icon-award-fill></span>
        </template>  
  </b-table>
</div>
</template>

<script>
import axios from 'axios';
import {AuthLevelEnum} from "./../enums"
export default {
  name: 'TutorGroupList',
  props: {
    person: Object,
    tutorGroupName: String
  },
  data: function() {
    return {
      tgFields: ['studentName', 'award', 'progress', {key: 'studentId', label: 'Actions'}],
      tutorGroup: [],
      authLevel: AuthLevelEnum
    }
  },
  methods: {
    showStudent(studentId){
      this.$emit('click', studentId);
    },
    giveAward(student){
      this.$emit('award', student.studentId);
      student.awardStatus = 1;
    },
    undoAward(student){
      this.$emit('undoAward', student.studentId);
      student.awardStatus = 0;
    },
    loadTutorGroup(tg){
      axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token') ?? 'missing';
      let that = this;
      if (tg != null) {
        axios.get(window.API_URL + 'students?tutorGroup=' + tg).then(function(response) {
          console.log(response)
          that.tutorGroup = response.data;
        });
      }
    }
  },
  watch: {
    tutorGroupName: function(val) {
      this.loadTutorGroup(val);
    }
  },
  mounted: function() {
    console.log('TutorGroupList mounted')
    console.log('TG', this.person.tutorGroup);
    this.loadTutorGroup(this.tutorGroupName);
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.awarded {
  color: gold;
}
.award-pending {
  color: goldenrod;
}
</style>
