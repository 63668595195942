<template>
  <b-container>
    <!--<router-link to="/bar">Go to Bar</router-link>-->
    <router-view :person="this.person" default="home"></router-view>
    <!--<student studentName="Brian"/>-->
  </b-container>
  
</template>

<script>

import axios from 'axios';
import {router} from './routes.js'
import {AuthLevelEnum} from "./enums"

axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token') ?? 'missing';

export default {
  name: 'App',
  //components: {
    //Student
  //}
  data() {
    return {
      person: {name: 'Jim', authLevel: 0}
    }
  },
  methods: {
    pushRouteTo(route) {
        try {
          if (router.name != route){
            router.replace({name: route});
          }
        } catch (error) {
          if (error.name != 'NavigationDuplicated') {
              throw error;
          }
        }
    }
  },
  mounted: function(){
    
    var that = this;
    console.log('App mounted');

    // axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token') ?? 'missing';

    axios.get(window.API_URL + 'auth').then(function(response) {
      console.log(response);

      that.person = response.data;
      if(response.data.authLevel === AuthLevelEnum.Student) { // Student
        //router.replace({name: 'student'})
        that.pushRouteTo('student');
      }
      else { // Staff
        //router.replace({name: 'staff'})  
        that.pushRouteTo('staff');
      }
    })
    .catch(function (err){
      if (err.response.status === 403 && window.locale === 'domain'){ //unauthenticated, redirect through OAuth
        window.location.href = window.oauthURL;
        return;
      }
    })
    //.failure(function(error){
      //console.log('Error', error);
    //});
    //router.push({name: 'bar'})
  },

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
